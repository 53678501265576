import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Show,
  Hide,
  Text,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  IconButton,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import StampDetails from './StampDetails';
import { NUMBER_KEYS } from '../../../utils/consts';
import placeholderBanner from '../../../assets/images/banner.png';
import { BANNER_BASE64 } from '../../../utils/base64';

function StampCardDetails(props) {
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeStepperColor = useColorModeValue('primaryDark', 'white');
  const modePrevNext = useColorModeValue('secondaryDark08', '#DDDDDD');

  const steps = Array.from({
    length: Number(passTemplateCtx.stampCount) + 1,
  }).map((item, index) => {
    return {
      title: `Stamp ${index}`,
      description: `Stamp ${index}`,
    };
  });

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    passTemplateCtx.updateActiveStep(activeStep);
    passTemplateCtx.updateBannerImage(
      passTemplateCtx[`stamp${NUMBER_KEYS[activeStep || 0]}Image`]
    );
  }, [activeStep]);

  useEffect(() => {
    if (!!props.stampActiveStep || props.stampActiveStep === 0) {
      setActiveStep(props.stampActiveStep);
      if (!props.isSubmitting) {
        window.scrollTo(0, 0);
      }
    }
  }, [props.stampActiveStep, props.isSubmitting]);

  useEffect(() => {
    if (passTemplateCtx.stampCount < activeStep) {
      setActiveStep(passTemplateCtx.stampCount);
    }
  }, [passTemplateCtx.stampCount]);

  useEffect(() => {
    const handleUpdate = (
      key,
      updateFunc,
      newValue,
      isImage,
      editedFieldKey
    ) => {
      if (passTemplateCtx[key] !== newValue) {
        updateFunc(isImage ? BANNER_BASE64 : newValue);

        if (props.isEdit) {
          const updateObj = isImage
            ? { ...props.editedImages, [editedFieldKey]: true }
            : { ...props.editedFields, [editedFieldKey]: newValue };

          isImage
            ? props.setEditedImages(updateObj)
            : props.setEditedFields(updateObj);
        }
      }
    };

    const updateStampFields = (stampKey) => {
      let lowercaseStampKey = stampKey.toLowerCase();
      const fields = [
        {
          type: 'stampImage',
          key: `stamp${stampKey}Image`,
          updateFunc: passTemplateCtx[`updateStamp${stampKey}Image`],
          newValue: placeholderBanner,
          editedFieldKey: `stamp_${lowercaseStampKey}_image`,
          isImage: true,
        },
        {
          type: 'stampWatermarkColor',
          key: `stamp${stampKey}WatermarkColor`,
          updateFunc: passTemplateCtx[`updateStamp${stampKey}WatermarkColor`],
          newValue: 'light',
          editedFieldKey: `stamp_${lowercaseStampKey}_watermark_color`,
          isImage: false,
        },
        {
          type: 'stampWatermarkPosition',
          key: `stamp${stampKey}WatermarkPosition`,
          updateFunc:
            passTemplateCtx[`updateStamp${stampKey}WatermarkPosition`],
          newValue: 'bottomRight',
          editedFieldKey: `stamp_${lowercaseStampKey}_watermark_position`,
          isImage: false,
        },
      ];

      fields.forEach(
        ({ key, updateFunc, newValue, isImage, editedFieldKey }) => {
          handleUpdate(key, updateFunc, newValue, isImage, editedFieldKey);
        }
      );
    };

    for (let i = passTemplateCtx.stampCount + 1; i <= 12; i++) {
      const stampKey = NUMBER_KEYS[i];
      updateStampFields(stampKey);
    }
  }, [passTemplateCtx.stampCount]);

  return (
    <>
      <Box className="autofillForDarkBg" borderRadius="15px">
        <Box
          className="primaryBoxShadow"
          bg={modeBoxBg}
          borderRadius="15px"
          overflow="hidden"
        >
          <FormControl
            bg={modeFormControlBg}
            isRequired
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px" pt={3}>
                  <FormLabel m={0}>Number of stamps</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pt={3}>Number of stamps</FormLabel>
                </Show>
                <Box>
                  <NumberInput
                    defaultValue={2}
                    min={2}
                    max={12}
                    keepWithinRange={true}
                    clampValueOnBlur={true}
                    value={passTemplateCtx.stampCount}
                    onChange={(value) => {
                      if (value === '') {
                        value = 2;
                      }
                      if (Number(value) > 12) {
                        value = 12;
                      }
                      passTemplateCtx.updateStampCount(Number(value));
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          stamp_count: value,
                        });
                      }
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper
                        children={
                          <CustomChevronLeftIcon
                            transform="rotate(90deg)"
                            w="18px"
                            h="18px"
                            color={modeStepperColor}
                          />
                        }
                      />
                      <NumberDecrementStepper
                        children={
                          <CustomChevronLeftIcon
                            transform="rotate(270deg)"
                            w="18px"
                            h="18px"
                            color={modeStepperColor}
                          />
                        }
                      />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Show above="2xl">
            <Flex alignItems="center" bg={modeFormControlBg}>
              <Box pl="24px" flexShrink={0} w="240px"></Box>
              <Box pt="10px" px={6} w="full" bg={modeLabelBg}>
                <Flex justifyContent="end">
                  <Text fontSize="12px">
                    Stamp {activeStep} of {passTemplateCtx.stampCount}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Show>
          <Hide above="sm">
            <Flex alignItems="center" bg={modeLabelBg}>
              <Box pt="10px" px={6} w="full" bg={modeLabelBg}>
                <Flex justifyContent="end">
                  <Text fontSize="12px">
                    Stamp {activeStep} of {passTemplateCtx.stampCount}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Hide>
          <Flex alignItems="center" bg={modeFormControlBg}>
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px"></Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl"></Show>
              <Flex direction="row" px={3}>
                <Show below="2xl">
                  <Hide below="md">
                    <Flex justifyContent="end">
                      <Box w="70px" />
                    </Flex>
                  </Hide>
                </Show>
                <Flex flexGrow={1} justifyContent="center">
                  <Stepper
                    colorScheme=""
                    size="xs"
                    index={activeStep}
                    gap={1}
                    justifyContent="center"
                    cursor="pointer"
                  >
                    {steps.map((step, index) => (
                      <Step key={index} onClick={() => setActiveStep(index)}>
                        <StepIndicator
                          bg={activeStep === index && '#F5796A'}
                          color={activeStep === index ? '#F5796A' : '#9F9F9F'}
                          cursor="pointer"
                          borderColor={
                            activeStep === index ? '#F5796A' : '#9F9F9F'
                          }
                          border={
                            activeStep === index
                              ? '2px solid #9F9F9F'
                              : '2px solid #9F9F9F'
                          }
                        ></StepIndicator>
                      </Step>
                    ))}
                  </Stepper>
                </Flex>
                <Show below="2xl">
                  <Hide below="md">
                    <Flex justifyContent="end">
                      <Text fontSize="12px">
                        Stamp {activeStep} of {passTemplateCtx.stampCount}
                      </Text>
                    </Flex>
                  </Hide>
                </Show>
              </Flex>
            </Box>
          </Flex>
          <StampDetails
            isEdit={props.isEdit}
            activeStep={activeStep}
            editedImages={props.editedImages}
            setEditedImages={props.setEditedImages}
            editedFields={props.editedFields}
            setEditedFields={props.setEditedFields}
            stampValuesError={props.stampValuesError}
          />
          {!!props.stampValuesError && (
            <Flex alignItems="center" bg={modeFormControlBg}>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px"></Box>
              </Hide>
              <Box pt="5px" w="full" bg={modeLabelBg}>
                <Show below="2xl"></Show>
                <Flex direction="row" justifyContent="center">
                  <Text
                    style={{
                      fontSize: '12px',
                      color: '#E53E3E',
                    }}
                  >
                    {props.stampValuesError}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          )}
          <Flex alignItems="center" bg={modeFormControlBg}>
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px"></Box>
            </Hide>
            <Box px="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl"></Show>
              <Flex direction="row" justifyContent="center" py={8}>
                <Flex order={{ base: 2, md: 1 }} mr="40px">
                  <IconButton
                    onClick={() => setActiveStep(activeStep - 1)}
                    isDisabled={activeStep === 0}
                    icon={
                      <CustomChevronLeftIcon
                        w="24px"
                        h="24px"
                        color={modePrevNext}
                      />
                    }
                    variant={useColorModeValue('secondary', 'white')}
                    size="sm"
                    w="36px"
                  />
                </Flex>
                <Flex
                  order={2}
                  width={{ base: '100%', md: 'auto' }}
                  justifyContent={{ base: 'center', md: 'none' }}
                  mb={{ base: '12px', md: '0' }}
                  alignItems="center"
                  fontSize="12px"
                >
                  <Hide below="sm">
                    <Text flexShrink="0">Go to stamp:</Text>{' '}
                  </Hide>
                  <NumberInput
                    size="sm"
                    ml={2}
                    mr={5}
                    w="72px"
                    minW="72px"
                    min={0}
                    max={passTemplateCtx.stampCount}
                    onChange={(value) => {
                      setActiveStep(Number(value));
                    }}
                    value={activeStep}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper
                        children={
                          <CustomChevronLeftIcon
                            transform="rotate(90deg)"
                            w="18px"
                            h="18px"
                            color={modeStepperColor}
                          />
                        }
                      />
                      <NumberDecrementStepper
                        children={
                          <CustomChevronLeftIcon
                            transform="rotate(270deg)"
                            w="18px"
                            h="18px"
                            color={modeStepperColor}
                          />
                        }
                      />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
                <Flex order={{ base: 3, md: 3 }} ml="30px">
                  <IconButton
                    onClick={() => setActiveStep(activeStep + 1)}
                    isDisabled={activeStep === passTemplateCtx.stampCount}
                    icon={
                      <CustomChevronLeftIcon
                        transform="rotate(180deg)"
                        w="24px"
                        h="24px"
                        color={modePrevNext}
                      />
                    }
                    variant={useColorModeValue('secondary', 'white')}
                    size="sm"
                    w="36px"
                  />
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
        {/* <Box mt={{ base: '30px' }} w="full">
          <Divider borderColor="primaryBackground" mb="15px" />
          <Box display="flex">
            <Button
              // disabled={
              //   ((!isDirty || !isValid || isSubmitting) && !isEdit) ||
              //   (isEdit && (!isValid || isSubmitting))
              // }
              variant="secondary"
              alt="Back"
              size="sm"
              mr="24px"
              onClick={() => props.setStampCardDetailsPage(false)}
            >
              Back
            </Button>
            <Button
              type="submit"
              // isLoading={isSubmitting}
              // disabled={
              //   ((!isDirty || !isValid || isSubmitting) && !isEdit) ||
              //   (isEdit && (!isValid || isSubmitting))
              // }
              alt="Save"
              size="sm"
              mr="24px"
            >
              Save
            </Button>
          </Box>
        </Box> */}
      </Box>
    </>
  );
}

export default StampCardDetails;
