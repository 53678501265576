import appsignal from '../../appsignal';
import {
  Container,
  Heading,
  Divider,
  Flex,
  Spacer,
  Button,
  Box,
  Grid,
  useToast,
  useColorModeValue,
  Text,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Breadcrumbs from '../../common/Breadcrumbs';
import TemplateDetails from '../../components/client/pass-templates/TemplateDetails';
import StampCardDetails from '../../components/client/pass-templates/StampCardDetails';
import PreviewPassTemplates from '../../components/client/pass-templates/PreviewPassTemplates';
import Prompt from '../../common/Prompt';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
import { useState, useContext } from 'react';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import { useNavigate, useParams } from 'react-router';
import CustomToast from '../../common/CustomToast';
import { BANNER_BASE64, getBase64DataForSubmit } from '../../utils/base64';
import placeholderLogo from '../../assets/images/logo.png';
import placeholderIcon from '../../assets/images/icon.png';
import placeholderBanner from '../../assets/images/banner.png';
import placeholderThumbnail from '../../assets/images/thumbnail.png';
import placeholderMonochromeBadge from '../../assets/images/monochrome-badge.png';
import RedirectionContext from '../../context/RedirectionContext';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';
import moment from 'moment';
import { NUMBER_KEYS } from '../../utils/consts';

function PassTemplateCreatePageForm({ isEdit }) {
  const { uuid } = useParams();
  const [queryParameters] = useSearchParams();
  const eventUuid = queryParameters.get('event');
  const { authAxios } = useContext(HttpContext);
  const meCtx = useContext(MeContext);
  const passTemplateCtx = useContext(PassTemplateContext);
  const redirectCtx = useContext(RedirectionContext);
  const [passTemplateName, setPassTemplateName] = useState(
    passTemplateCtx.templateName
  );
  const [loading, setLoading] = useState(true);
  const [templateNameError, setTemplateNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [headerError, setHeaderError] = useState('');
  const [templateEntityError, setTemplateEntityError] = useState('');
  const [backgroundHexError, setBackgroundHexError] = useState('');
  const [isValidImage, setIsValidImage] = useState(false);
  const [bodyHexError, setBodyHexError] = useState('');
  const [titleHexError, setTitleHexError] = useState('');
  const [editedFields, setEditedFields] = useState({});
  const [editedImages, setEditedImages] = useState({});
  const [stampCardDetailsPage, setStampCardDetailsPage] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const [templateValidationErrors, setTemplateValidationErrors] =
    useState(false);
  const [stampValuesError, setStampValuesError] = useState('');
  const [stampActiveStep, setStampActiveStep] = useState(null);
  const { entities, filterEntity, watermarkEnabled } = meCtx.state;

  const templateNames =
    meCtx.state?.passTemplates.map((passTemplate) => passTemplate.name) || [];
  function isTemplateNameUnique(value) {
    // Check if the value exists in the array of existing template names
    return !templateNames.includes(value);
  }

  const formOptions = {
    // resolver: yupResolver(validationSchema),
    // mode: 'onChange',
  };

  const methods = useForm(formOptions);
  const { isSubmitting } = methods.formState;

  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');

  // if (isEdit && passTemplateCtx.templateName === '') {
  //   return (
  //     <EmptyState heading="" text="" noImage>
  //       <Spinner
  //         width="52px"
  //         height="52px"
  //         thickness="4px"
  //         speed="0.65s"
  //         emptyColor="quinaryBackground"
  //         mt={4}
  //         mx="auto"
  //       />
  //     </EmptyState>
  //   );
  // }
  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass templates',
      linkUrl: '/pass-templates/',
    },
    {
      linkName: isEdit
        ? passTemplateCtx.templateName
        : 'Create a new pass template',
      linkUrl: null,
    },
  ];

  function handleImage(image, placeholder, submittedData, propertyName) {
    if (image !== placeholder) {
      let imageBase64 = getBase64DataForSubmit(image);
      if (imageBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          [`${propertyName}Base64`]: imageBase64,
        };
      }
    }
  }

  const clearStampValuesOnEdit = (fieldType) => {
    setEditedFields({
      ...editedFields,
      [`stamp_zero_${fieldType}_value`]: '',
      [`stamp_one_${fieldType}_value`]: '',
      [`stamp_two_${fieldType}_value`]: '',
      [`stamp_three_${fieldType}_value`]: '',
      [`stamp_four_${fieldType}_value`]: '',
      [`stamp_five_${fieldType}_value`]: '',
      [`stamp_six_${fieldType}_value`]: '',
      [`stamp_seven_${fieldType}_value`]: '',
      [`stamp_eight_${fieldType}_value`]: '',
      [`stamp_nine_${fieldType}_value`]: '',
      [`stamp_ten_${fieldType}_value`]: '',
      [`stamp_eleven_${fieldType}_value`]: '',
      [`stamp_twelve_${fieldType}_value`]: '',
    });
  };

  function prepareDataForSubmit() {
    const {
      bodyFontColor,
      passBackgroundColor,
      titleFontColor,
      passType,
      headerOneId,
      headerOneLabel,
      headerOneValue,
      centralTitle,
      googleNfcEnabled,
      primaryOneId,
      primaryOneLabel,
      primaryOneValue,
      logoImage,
      thumbnailImage,
      monochromeBadge,
      iconLogo,
      bannerImage,
      googleBannerImage,
      secOneId,
      secOneLabel,
      secOneValue,
      secTwoId,
      secTwoLabel,
      secTwoValue,
      secThreeId,
      secThreeLabel,
      secThreeValue,
      auxOneId,
      auxOneLabel,
      auxOneValue,
      auxTwoId,
      auxTwoLabel,
      auxTwoValue,
      auxThreeId,
      auxThreeLabel,
      auxThreeValue,
      description,
      backOneId,
      backOneLabel,
      backOneValue,
      backTwoId,
      backTwoLabel,
      backTwoValue,
      backThreeId,
      backThreeLabel,
      backThreeValue,
      backFourId,
      backFourLabel,
      backFourValue,
      backFiveId,
      backFiveLabel,
      backFiveValue,
      notificationHeader,
      appleAppLink,
      googleAppLinkPlayStoreUrl,
      googleAppLinkPackageName,
      googleAppLinkWebAppUrl,
      stampCount,
      stampShortKey,
      stampLongKey,
      stampZeroMessage,
      stampOneMessage,
      stampTwoMessage,
      stampThreeMessage,
      stampFourMessage,
      stampFiveMessage,
      stampSixMessage,
      stampSevenMessage,
      stampEightMessage,
      stampNineMessage,
      stampTenMessage,
      stampElevenMessage,
      stampTwelveMessage,
      stampZeroShortValue,
      stampOneShortValue,
      stampTwoShortValue,
      stampThreeShortValue,
      stampFourShortValue,
      stampFiveShortValue,
      stampSixShortValue,
      stampSevenShortValue,
      stampEightShortValue,
      stampNineShortValue,
      stampTenShortValue,
      stampElevenShortValue,
      stampTwelveShortValue,
      stampZeroLongValue,
      stampOneLongValue,
      stampTwoLongValue,
      stampThreeLongValue,
      stampFourLongValue,
      stampFiveLongValue,
      stampSixLongValue,
      stampSevenLongValue,
      stampEightLongValue,
      stampNineLongValue,
      stampTenLongValue,
      stampElevenLongValue,
      stampTwelveLongValue,
      stampZeroImage,
      stampOneImage,
      stampTwoImage,
      stampThreeImage,
      stampFourImage,
      stampFiveImage,
      stampSixImage,
      stampSevenImage,
      stampEightImage,
      stampNineImage,
      stampTenImage,
      stampElevenImage,
      stampTwelveImage,
      stampZeroWatermarkColor,
      stampZeroWatermarkPosition,
      stampOneWatermarkColor,
      stampOneWatermarkPosition,
      stampTwoWatermarkColor,
      stampTwoWatermarkPosition,
      stampThreeWatermarkColor,
      stampThreeWatermarkPosition,
      stampFourWatermarkColor,
      stampFourWatermarkPosition,
      stampFiveWatermarkColor,
      stampFiveWatermarkPosition,
      stampSixWatermarkColor,
      stampSixWatermarkPosition,
      stampSevenWatermarkColor,
      stampSevenWatermarkPosition,
      stampEightWatermarkColor,
      stampEightWatermarkPosition,
      stampNineWatermarkColor,
      stampNineWatermarkPosition,
      stampTenWatermarkColor,
      stampTenWatermarkPosition,
      stampElevenWatermarkColor,
      stampElevenWatermarkPosition,
      stampTwelveWatermarkColor,
      stampTwelveWatermarkPosition,
      locations,
      beacons,
      watermarkColor,
      watermarkPosition,
      googleBannerWatermarkColor,
      googleBannerWatermarkPosition,
      loyaltyConfigEnabled,
      loyaltyConfigNumberType,
    } = passTemplateCtx;

    let submittedData = {};

    let locationsData = [];
    let beaconsData = [];

    if (!!locations.length > 0) {
      locations.forEach((location) => {
        locationsData.push({
          name: location.name,
          latitude: location.latitude,
          longitude: location.longitude,
          radius: Number(location.radius),
          message: location.message,
        });
      });
    }

    if (!!beacons.length > 0) {
      beacons.forEach((beacon) => {
        beaconsData.push({
          name: beacon.name,
          proximity_uuid: beacon.proximity_uuid,
          major: Number(beacon.major),
          minor: Number(beacon.minor),
          message: beacon.message,
        });
      });
    }

    if (isEdit) {
      submittedData = editedFields;
      if (editedFields['locations']) {
        submittedData['locations'] = locationsData;
      }
      if (editedFields['beacons']) {
        submittedData['beacons'] = beaconsData;
      }
      if (passType === 'stampCard') {
        if (!stampShortKey) {
          clearStampValuesOnEdit('short');
        }
        if (!stampLongKey) {
          clearStampValuesOnEdit('long');
        }
      }
      if (
        editedFields['loyalty_config_attributes'] &&
        passType === 'storeCard'
      ) {
        if (editedFields['loyalty_config_attributes']['enabled'] === false) {
          submittedData['loyalty_config_attributes'] = {
            enabled: loyaltyConfigEnabled,
            numberType: 'integer',
          };
        }
      }
    } else {
      submittedData = {
        name: passTemplateName,
        template_type: passType,
        header_one_id: headerOneId,
        header_one_label: headerOneLabel,
        header_one_default_value: passType === 'stampCard' ? 0 : headerOneValue,
        central_title: centralTitle,
        google_nfc_enabled: googleNfcEnabled,
        primary_one_id: primaryOneId,
        primary_one_label: primaryOneLabel,
        primary_one_default_value: primaryOneValue,
        logo_image: logoImage,
        thumbnail_image: thumbnailImage,
        monochrome_badge: monochromeBadge,
        icon_image: iconLogo,
        banner_image: passType === 'stampCard' ? stampZeroImage : bannerImage,
        google_banner_image: googleBannerImage,
        sec_one_id: secOneId,
        sec_one_label: secOneLabel,
        sec_one_default_value: secOneValue,
        sec_two_id: secTwoId,
        sec_two_label: secTwoLabel,
        sec_two_default_value: secTwoValue,
        sec_three_id: secThreeId,
        sec_three_label: secThreeLabel,
        sec_three_default_value: secThreeValue,
        aux_one_id: auxOneId,
        aux_one_label: auxOneLabel,
        aux_one_default_value: auxOneValue,
        aux_two_id: auxTwoId,
        aux_two_label: auxTwoLabel,
        aux_two_default_value: auxTwoValue,
        aux_three_id: auxThreeId,
        aux_three_label: auxThreeLabel,
        aux_three_default_value: auxThreeValue,
        description: description,
        back_one_id: backOneId,
        back_one_label: backOneLabel,
        back_one_default_value: backOneValue,
        back_two_id: backTwoId,
        back_two_label: backTwoLabel,
        back_two_default_value: backTwoValue,
        back_three_id: backThreeId,
        back_three_label: backThreeLabel,
        back_three_default_value: backThreeValue,
        back_four_id: backFourId,
        back_four_label: backFourLabel,
        back_four_default_value: backFourValue,
        back_five_id: backFiveId,
        back_five_label: backFiveLabel,
        back_five_default_value: backFiveValue,
        text_color: bodyFontColor,
        background_color: passBackgroundColor,
        label_color: titleFontColor,
        notification_header: notificationHeader,
        apple_app_link: appleAppLink,
        google_app_link_play_store_url: googleAppLinkPlayStoreUrl,
        google_app_link_package_name: googleAppLinkPackageName,
        google_app_link_web_app_url: googleAppLinkWebAppUrl,
        locations: locationsData,
        beacons: beaconsData,
        watermark_color: watermarkColor,
        watermark_position: watermarkPosition,
        google_banner_watermark_color: googleBannerWatermarkColor,
        google_banner_watermark_position: googleBannerWatermarkPosition,
      };

      if (passType === 'storeCard') {
        submittedData = {
          ...submittedData,
          loyaltyConfigAttributes: {
            enabled: loyaltyConfigEnabled,
            numberType: loyaltyConfigNumberType,
          },
        };
      }

      if (passType === 'stampCard') {
        submittedData = {
          ...submittedData,
          stamp_count: Number(stampCount),
          stamp_short_key: stampShortKey,
          stamp_long_key: stampLongKey,
          stamp_zero_message: stampZeroMessage,
          stamp_one_message: stampOneMessage,
          stamp_two_message: stampTwoMessage,
          stamp_three_message: stampThreeMessage,
          stamp_four_message: stampFourMessage,
          stamp_five_message: stampFiveMessage,
          stamp_six_message: stampSixMessage,
          stamp_seven_message: stampSevenMessage,
          stamp_eight_message: stampEightMessage,
          stamp_nine_message: stampNineMessage,
          stamp_ten_message: stampTenMessage,
          stamp_eleven_message: stampElevenMessage,
          stamp_twelve_message: stampTwelveMessage,
          stamp_zero_short_value: stampZeroShortValue,
          stamp_one_short_value: stampOneShortValue,
          stamp_two_short_value: stampTwoShortValue,
          stamp_three_short_value: stampThreeShortValue,
          stamp_four_short_value: stampFourShortValue,
          stamp_five_short_value: stampFiveShortValue,
          stamp_six_short_value: stampSixShortValue,
          stamp_seven_short_value: stampSevenShortValue,
          stamp_eight_short_value: stampEightShortValue,
          stamp_nine_short_value: stampNineShortValue,
          stamp_ten_short_value: stampTenShortValue,
          stamp_eleven_short_value: stampElevenShortValue,
          stamp_twelve_short_value: stampTwelveShortValue,
          stamp_zero_long_value: stampZeroLongValue,
          stamp_one_long_value: stampOneLongValue,
          stamp_two_long_value: stampTwoLongValue,
          stamp_three_long_value: stampThreeLongValue,
          stamp_four_long_value: stampFourLongValue,
          stamp_five_long_value: stampFiveLongValue,
          stamp_six_long_value: stampSixLongValue,
          stamp_seven_long_value: stampSevenLongValue,
          stamp_eight_long_value: stampEightLongValue,
          stamp_nine_long_value: stampNineLongValue,
          stamp_ten_long_value: stampTenLongValue,
          stamp_eleven_long_value: stampElevenLongValue,
          stamp_twelve_long_value: stampTwelveLongValue,
          stamp_zero_image: stampZeroImage,
          stamp_one_image: stampOneImage,
          stamp_two_image: stampTwoImage,
          stamp_three_image: stampThreeImage,
          stamp_four_image: stampFourImage,
          stamp_five_image: stampFiveImage,
          stamp_six_image: stampSixImage,
          stamp_seven_image: stampSevenImage,
          stamp_eight_image: stampEightImage,
          stamp_nine_image: stampNineImage,
          stamp_ten_image: stampTenImage,
          stamp_eleven_image: stampElevenImage,
          stamp_twelve_image: stampTwelveImage,
          stamp_zero_watermark_color: stampZeroWatermarkColor,
          stamp_zero_watermark_position: stampZeroWatermarkPosition,
          stamp_one_watermark_color: stampOneWatermarkColor,
          stamp_one_watermark_position: stampOneWatermarkPosition,
          stamp_two_watermark_color: stampTwoWatermarkColor,
          stamp_two_watermark_position: stampTwoWatermarkPosition,
          stamp_three_watermark_color: stampThreeWatermarkColor,
          stamp_three_watermark_position: stampThreeWatermarkPosition,
          stamp_four_watermark_color: stampFourWatermarkColor,
          stamp_four_watermark_position: stampFourWatermarkPosition,
          stamp_five_watermark_color: stampFiveWatermarkColor,
          stamp_five_watermark_position: stampFiveWatermarkPosition,
          stamp_six_watermark_color: stampSixWatermarkColor,
          stamp_six_watermark_position: stampSixWatermarkPosition,
          stamp_seven_watermark_color: stampSevenWatermarkColor,
          stamp_seven_watermark_position: stampSevenWatermarkPosition,
          stamp_eight_watermark_color: stampEightWatermarkColor,
          stamp_eight_watermark_position: stampEightWatermarkPosition,
          stamp_nine_watermark_color: stampNineWatermarkColor,
          stamp_nine_watermark_position: stampNineWatermarkPosition,
          stamp_ten_watermark_color: stampTenWatermarkColor,
          stamp_ten_watermark_position: stampTenWatermarkPosition,
          stamp_eleven_watermark_color: stampElevenWatermarkColor,
          stamp_eleven_watermark_position: stampElevenWatermarkPosition,
          stamp_twelve_watermark_color: stampTwelveWatermarkColor,
          stamp_twelve_watermark_position: stampTwelveWatermarkPosition,
        };
      }
    }

    handleImage(logoImage, placeholderLogo, submittedData, 'logoImage');

    handleImage(
      thumbnailImage,
      placeholderThumbnail,
      submittedData,
      'thumbnailImage'
    );
    handleImage(
      monochromeBadge,
      placeholderMonochromeBadge,
      submittedData,
      'monochromeBadge'
    );
    handleImage(iconLogo, placeholderIcon, submittedData, 'iconLogo');
    handleImage(bannerImage, placeholderBanner, submittedData, 'bannerImage');
    handleImage(
      googleBannerImage,
      placeholderBanner,
      submittedData,
      'googleBannerImage'
    );

    handleImage(
      stampZeroImage,
      placeholderBanner,
      submittedData,
      'stampZeroImage'
    );
    handleImage(
      stampOneImage,
      placeholderBanner,
      submittedData,
      'stampOneImage'
    );
    handleImage(
      stampTwoImage,
      placeholderBanner,
      submittedData,
      'stampTwoImage'
    );
    handleImage(
      stampThreeImage,
      placeholderBanner,
      submittedData,
      'stampThreeImage'
    );
    handleImage(
      stampFourImage,
      placeholderBanner,
      submittedData,
      'stampFourImage'
    );
    handleImage(
      stampFiveImage,
      placeholderBanner,
      submittedData,
      'stampFiveImage'
    );
    handleImage(
      stampSixImage,
      placeholderBanner,
      submittedData,
      'stampSixImage'
    );
    handleImage(
      stampSevenImage,
      placeholderBanner,
      submittedData,
      'stampSevenImage'
    );
    handleImage(
      stampEightImage,
      placeholderBanner,
      submittedData,
      'stampEightImage'
    );
    handleImage(
      stampNineImage,
      placeholderBanner,
      submittedData,
      'stampNineImage'
    );
    handleImage(
      stampTenImage,
      placeholderBanner,
      submittedData,
      'stampTenImage'
    );
    handleImage(
      stampElevenImage,
      placeholderBanner,
      submittedData,
      'stampElevenImage'
    );
    handleImage(
      stampTwelveImage,
      placeholderBanner,
      submittedData,
      'stampTwelveImage'
    );

    if (isEdit) {
      if (editedImages['logo_image']) {
        submittedData['logo_image'] = logoImage;
      }
      if (passType === 'generic' && !thumbnailImage) {
        submittedData['thumbnail_image'] = '';
      }
      if (passType === 'generic' && !bannerImage) {
        submittedData['banner_image'] = '';
      }
      if (!googleBannerImage) {
        submittedData['google_banner_image'] = '';
      }
      if (editedImages['thumbnail_image']) {
        submittedData['thumbnail_image'] = thumbnailImage;
      }
      if (editedImages['monochrome_badge']) {
        submittedData['monochrome_badge'] = monochromeBadge;
      }
      if (editedImages['icon_image']) {
        submittedData['icon_image'] = iconLogo;
      }
      if (editedImages['banner_image']) {
        submittedData['banner_image'] = bannerImage;
      }
      if (editedImages['google_banner_image']) {
        submittedData['google_banner_image'] = googleBannerImage;
      }
      if (editedImages['stamp_zero_image']) {
        submittedData['stamp_zero_image'] = stampZeroImage;
        submittedData['banner_image'] = stampZeroImage;
      }
      if (editedImages['stamp_one_image']) {
        submittedData['stamp_one_image'] = stampOneImage;
      }
      if (editedImages['stamp_two_image']) {
        submittedData['stamp_two_image'] = stampTwoImage;
      }
      if (editedImages['stamp_three_image']) {
        submittedData['stamp_three_image'] = stampThreeImage;
      }
      if (editedImages['stamp_four_image']) {
        submittedData['stamp_four_image'] = stampFourImage;
      }
      if (editedImages['stamp_five_image']) {
        submittedData['stamp_five_image'] = stampFiveImage;
      }
      if (editedImages['stamp_six_image']) {
        submittedData['stamp_six_image'] = stampSixImage;
      }
      if (editedImages['stamp_seven_image']) {
        submittedData['stamp_seven_image'] = stampSevenImage;
      }
      if (editedImages['stamp_eight_image']) {
        submittedData['stamp_eight_image'] = stampEightImage;
      }
      if (editedImages['stamp_nine_image']) {
        submittedData['stamp_nine_image'] = stampNineImage;
      }
      if (editedImages['stamp_ten_image']) {
        submittedData['stamp_ten_image'] = stampTenImage;
      }
      if (editedImages['stamp_eleven_image']) {
        submittedData['stamp_eleven_image'] = stampElevenImage;
      }
      if (editedImages['stamp_twelve_image']) {
        submittedData['stamp_twelve_image'] = stampTwelveImage;
      }
      submittedData['text_color'] = bodyFontColor;
      submittedData['background_color'] = passBackgroundColor;
      submittedData['label_color'] = titleFontColor;

      if (passType !== 'stampCard') {
        Object.keys(submittedData).forEach((key) => {
          if (key.includes('stamp')) {
            delete submittedData[key];
          }
        });
      }

      if (!watermarkEnabled) {
        Object.keys(submittedData).forEach((key) => {
          if (key.includes('watermark')) {
            delete submittedData[key];
          }
        });
      }
    }
    return submittedData;
  }

  const validateFields = () => {
    const { description } = passTemplateCtx;
    let nameErrorMessage = '';
    let descriptionError = '';
    let headerErrorMessage = '';

    if (!passTemplateName) {
      nameErrorMessage = 'Please enter a template name';
    } else if (!isEdit && !isTemplateNameUnique(passTemplateName)) {
      nameErrorMessage = 'Template name must be unique';
    } else if (
      isEdit &&
      passTemplateName !== passTemplateCtx.templateName &&
      !isTemplateNameUnique(passTemplateName)
    ) {
      nameErrorMessage = 'Template name must be unique';
    }

    if (!description) {
      descriptionError = 'Please enter a description';
    }

    if (
      passTemplateCtx.passType !== 'generic' &&
      !passTemplateCtx.headerOneId &&
      !passTemplateCtx.headerOneLabel &&
      !passTemplateCtx.headerOneValue
    ) {
      headerErrorMessage = 'Field is required';
    }

    setTemplateNameError(nameErrorMessage);
    setDescriptionError(descriptionError);
    setHeaderError(headerErrorMessage);

    const validationError =
      !!descriptionError ||
      !!nameErrorMessage ||
      !!headerErrorMessage ||
      (!!passTemplateCtx.googleAppLinkIconUrl && !isValidImage);

    if (nameErrorMessage) {
      window.scrollTo(0, 0);
    }
    return validationError;
  };

  const validateStampValues = () => {
    let stampValuesMessage = '';

    if (!!passTemplateCtx.stampShortKey || !!passTemplateCtx.stampLongKey) {
      const stampCount = passTemplateCtx.stampCount;
      const stampKeys = Object.keys(NUMBER_KEYS)
        .filter((key) => parseInt(key) <= stampCount)
        .map((key) => NUMBER_KEYS[key]);

      for (let i = 0; i <= stampCount; i++) {
        if (
          (!!passTemplateCtx.stampShortKey &&
            !passTemplateCtx[`stamp${stampKeys[i]}ShortValue`]) ||
          (!!passTemplateCtx.stampLongKey &&
            !passTemplateCtx[`stamp${stampKeys[i]}LongValue`])
        ) {
          setStampActiveStep(i);
          stampValuesMessage = 'Please fill in all required fields.';
          setStampValuesError(stampValuesMessage);
          return stampValuesMessage;
        }
      }
    }
    setStampActiveStep(null);
    setStampValuesError(stampValuesMessage);
    return !!stampValuesMessage;
  };

  const validateImageUrl = (url) => {
    const img = new Image();
    img.onload = () => {
      setIsValidImage(true);
    };
    img.onerror = () => {
      setIsValidImage(false);
    };
    img.src = url;
  };

  useEffect(() => {
    if (passTemplateCtx.stampShortKey === '') {
      passTemplateCtx.clearAllStampKeyValues('Short');
    }
  }, [passTemplateCtx.stampShortKey]);

  useEffect(() => {
    if (passTemplateCtx.stampLongKey === '') {
      passTemplateCtx.clearAllStampKeyValues('Long');
    }
  }, [passTemplateCtx.stampLongKey]);

  useEffect(() => {
    validateImageUrl(passTemplateCtx.googleAppLinkIconUrl);
  }, [passTemplateCtx.googleAppLinkIconUrl]);

  const onSubmit = async (values) => {
    let loadingToast;
    const validationError = validateFields();
    const stampCardValidationError =
      passTemplateCtx.passType === 'stampCard' ? validateStampValues() : false;
    setTemplateValidationErrors(validationError);
    async function handleEdit() {
      if (!validationError && !stampCardValidationError) {
        try {
          loadingToast = toast({
            // duration: null,
            render: ({ onClose }) => (
              <CustomToast
                status="info"
                title={'Editing pass template'}
                description="This may take a moment, please do not refresh the page."
                onClose={onClose}
              />
            ),
          });

          const submittedData = prepareDataForSubmit(values);

          const { data: responseData } = await authAxios.patch(
            `passes/templates/${uuid}`,
            submittedData
          );

          passTemplateCtx.updateAllData(responseData.data);

          toast.close(loadingToast);

          navigate('/pass-templates', { replace: true });

          toast({
            render: ({ onClose }) => (
              <CustomToast
                status="success"
                title="Pass template has been edited"
                description=""
                onClose={onClose}
              />
            ),
          });
        } catch (error) {
          appsignal.sendError(error);
          console.log(error);
          error.response.data.forEach((errorMessage) => {
            toast({
              render: ({ onClose }) => (
                <CustomToast
                  status="error"
                  title={errorMessage}
                  description=""
                  onClose={onClose}
                />
              ),
            });
          });
          const { errors } = getErrorResponsePayload(error);
          if (errors && errors.name.errors) {
            console.log(errors.name.errors);
            setTemplateNameError(errors.name.errors[0]);
          }
          if (errors && errors.description.errors) {
            console.log(errors.description.errors);
            setDescriptionError(errors.description.errors[0]);
          }
          if (errors && errors.entity.errors) {
            console.log(errors.entity.errors);
            setTemplateEntityError(errors.entity.errors[0]);
          }
          if (errors && errors.passBackgroundColor.errors) {
            setBackgroundHexError(
              'Enter a valid 6-digit hexadecimal colour code.'
            );
          }
          if (errors && errors.bodyFontColor.errors) {
            setBodyHexError('Enter a valid 6-digit hexadecimal colour code.');
          }
          if (errors && errors.titleFontColor.errors) {
            setTitleHexError('Enter a valid 6-digit hexadecimal colour code.');
          }
        }
      }
    }

    try {
      if (isEdit) {
        await handleEdit();
        return;
      }
      if (!validationError && !stampCardValidationError) {
        loadingToast = toast({
          // duration: null,
          render: ({ onClose }) => (
            <CustomToast
              status="info"
              title={'Creating pass template'}
              description="This may take a moment, please do not refresh the page."
              onClose={onClose}
            />
          ),
        });

        const submittedData = prepareDataForSubmit(values);

        let response;
        if (eventUuid) {
          response = await authAxios.post(
            `api/v1/events/${eventUuid}/passes/templates`,
            submittedData
          );
        } else if (filterEntity) {
          response = await authAxios.post(
            `api/v1/entities/${filterEntity.uuid}/passes/templates`,
            submittedData
          );
        } else {
          response = await authAxios.post(`passes/templates`, submittedData);
        }
        if (redirectCtx.passTemplateFromPassState) {
          redirectCtx.updateSavedPassTemplate(response.data.data);
          navigate('/passes/create', { replace: true });
        }
        if (redirectCtx.passTemplateFromEditPassState) {
          redirectCtx.updateSavedPassTemplate(response.data.data);
          navigate(`/passes/edit/${redirectCtx.passParamUrl}`, {
            replace: true,
          });
        }
        toast.close(loadingToast);
        navigate('/pass-templates', { replace: true });
        // }

        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="success"
              title="Pass template has been created"
              description=""
              onClose={onClose}
            />
          ),
        });
      }
    } catch (error) {
      appsignal.sendError(error);
      toast.close(loadingToast);
      error.response.data.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title={errorMessage}
              description=""
              onClose={onClose}
            />
          ),
        });
      });
      passTemplateCtx.updateDisplayBannerWatermarkPreview(true);
      const { errors } = getErrorResponsePayload(error);
      if (errors && errors.name.errors) {
        setTemplateNameError(errors.name.errors[0]);
      }
      if (errors && errors.description.errors) {
        setDescriptionError(errors.description.errors[0]);
      }
      if (errors && errors.entity.errors) {
        setTemplateEntityError(errors.entity.errors[0]);
      }
      if (errors && errors.passBackgroundColor.errors) {
        setBackgroundHexError('Enter a valid 6-digit hexadecimal colour code.');
      }
      if (errors && errors.bodyFontColor.errors) {
        setBodyHexError('Enter a valid 6-digit hexadecimal colour code.');
      }
      if (errors && errors.titleFontColor.errors) {
        setTitleHexError('Enter a valid 6-digit hexadecimal colour code.');
      }
    }
  };

  useEffect(() => {
    if (
      passTemplateCtx.passType === 'generic' ||
      passTemplateCtx.headerOneId ||
      passTemplateCtx.headerOneLabel ||
      passTemplateCtx.headerOneValue
    ) {
      setHeaderError('');
    } else {
      setHeaderError('Field is required');
    }
  }, [
    passTemplateCtx.passType,
    passTemplateCtx.headerOneId,
    passTemplateCtx.headerOneLabel,
    passTemplateCtx.headerOneValue,
  ]);

  useEffect(() => {
    if (eventUuid) {
      const fetchEvent = async () => {
        try {
          const response = await authAxios.get(`api/v1/events/${eventUuid}`);
          const eventData = response?.data?.data?.attributes;
          passTemplateCtx.updatePassType('eventTicket');
          passTemplateCtx.updateSecOneValue(eventData?.name);
          passTemplateCtx.updateSecThreeValue(
            moment(eventData?.startTime)
              .tz(eventData.timeZone)
              .format('HH:mm A')
          );
          passTemplateCtx.updateSecTwoValue(eventData?.venueName);
          passTemplateCtx.updateCentralTitle(eventData?.name);
          passTemplateCtx.updateSecOneId('event');
          passTemplateCtx.updateSecOneLabel('Event');
          passTemplateCtx.updateSecTwoId('venue');
          passTemplateCtx.updateSecTwoLabel('Venue');
          passTemplateCtx.updateSecThreeId('time');
          passTemplateCtx.updateSecThreeLabel('Time');
        } catch (error) {
          appsignal.sendError(error);
          console.log(error);
          const { message, code } = getErrorResponsePayload(error);
          code !== 401 &&
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={message ? message : `Something went wrong`}
                  description={!message && 'Please try again later'}
                  onClose={props.onClose}
                />
              ),
            });
        }
      };

      fetchEvent();
    }
  }, [queryParameters]);

  useEffect(() => {
    // Only set the templateName if passTemplateCtx is available and templateName is not set yet
    if (passTemplateCtx && passTemplateCtx.templateName && loading) {
      setPassTemplateName(passTemplateCtx.templateName);
      setLoading(false);
    }
  }, [passTemplateCtx, loading]);

  useEffect(() => {
    if (isSubmitting) {
      const validationError = validateFields();
      setTemplateValidationErrors(validationError);

      if (validationError) {
        setStampCardDetailsPage(false);
      }
    }
  }, [templateValidationErrors, isSubmitting]);

  return (
    // FormProvider - pass all methods into the context (https://react-hook-form.com/api/useformcontext)
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container display="flex" flexDir="column" flex="1" pb={6}>
          <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
          <Flex
            flexDirection={{ base: 'column', sm: 'row' }}
            alignItems="center"
            flexWrap="wrap"
            mt="15px"
            py="9px"
            minH="54px"
          >
            <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
              {isEdit
                ? `Edit ${passTemplateCtx.templateName}`
                : 'Create a new pass template'}{' '}
              {isEdit && (
                <Text
                  as="span"
                  display="inline-block"
                  textStyle="bodyFamilyMedium"
                  fontSize="14px"
                  color={modeHelperText}
                  lineHeight="130%"
                >
                  / uuid: {passTemplateCtx.uuid}
                </Text>
              )}
            </Heading>
            <Spacer />
          </Flex>
          <Divider borderColor="primaryBackground" mb={6} />

          {isSetUpConfirmed ? (
            <>
              <Grid
                templateColumns={{
                  base: '100%',
                  xl: stampCardDetailsPage ? '1fr 1fr' : '40% 60%',
                }}
                gap={6}
              >
                {!stampCardDetailsPage ? (
                  <TemplateDetails
                    isEdit={isEdit}
                    editedFields={editedFields}
                    setEditedFields={setEditedFields}
                    editedImages={editedImages}
                    setEditedImages={setEditedImages}
                    eventUuid={eventUuid}
                    templateNameError={templateNameError}
                    descriptionError={descriptionError}
                    headerError={headerError}
                    templateEntityError={templateEntityError}
                    bodyHexError={bodyHexError}
                    backgroundHexError={backgroundHexError}
                    titleHexError={titleHexError}
                    templateName={passTemplateName}
                    setTemplateName={setPassTemplateName}
                    isValidImage={isValidImage}
                  />
                ) : (
                  <StampCardDetails
                    isEdit={isEdit}
                    editedFields={editedFields}
                    setEditedFields={setEditedFields}
                    editedImages={editedImages}
                    setEditedImages={setEditedImages}
                    eventUuid={eventUuid}
                    templateNameError={templateNameError}
                    templateEntityError={templateEntityError}
                    bodyHexError={bodyHexError}
                    backgroundHexError={backgroundHexError}
                    titleHexError={titleHexError}
                    templateName={passTemplateName}
                    setTemplateName={setPassTemplateName}
                    isValidImage={isValidImage}
                    setStampCardDetailsPage={setStampCardDetailsPage}
                    isSubmitting={isSubmitting}
                    stampValuesError={stampValuesError}
                    setStampValuesError={setStampValuesError}
                    stampActiveStep={stampActiveStep}
                  />
                )}
                <PreviewPassTemplates
                  createTemplate
                  stampCardDetailsPage={stampCardDetailsPage}
                />
              </Grid>
              <Box mt={{ base: '30px' }} w="full">
                <Divider borderColor="primaryBackground" mb="15px" />
                <Box display="flex">
                  {passTemplateCtx.passType === 'stampCard' &&
                    !stampCardDetailsPage && (
                      <Button
                        isDisabled={
                          entities.length > 1 && !filterEntity && !isEdit
                        }
                        alt="Next"
                        size="sm"
                        mr="24px"
                        onClick={() => setStampCardDetailsPage(true)}
                        // as={RouterLink}
                        // to={{
                        //   pathname: '/pass-templates/create/stamp-card',
                        // }}
                      >
                        Next
                      </Button>
                    )}
                  {(passTemplateCtx.passType !== 'stampCard' ||
                    (passTemplateCtx.passType === 'stampCard' &&
                      !!stampCardDetailsPage)) && (
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={
                        entities.length > 1 && !filterEntity && !isEdit
                      }
                      alt="Save"
                      size="sm"
                      mr="24px"
                    >
                      Save
                    </Button>
                  )}
                  {!!stampCardDetailsPage && (
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => setStampCardDetailsPage(false)}
                      isDisabled={isSubmitting}
                    >
                      Back
                    </Button>
                  )}

                  {!stampCardDetailsPage && (
                    <Button
                      size="sm"
                      variant="secondary"
                      isDisabled={isSubmitting}
                      as={RouterLink}
                      to={{
                        pathname: redirectCtx.passTemplateFromPassState
                          ? '/passes/create'
                          : redirectCtx.passTemplateFromEditPassState
                            ? `/passes/edit/${redirectCtx.passParamUrl}`
                            : '/pass-templates',
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Prompt
              title="Your account setup is in progress"
              description={
                <Text fontSize="14px">
                  If you would like an update please reach out to{' '}
                  <Link href="mailto:sales@passentry.com" color="">
                    sales@passentry.com
                  </Link>
                  .
                </Text>
              }
            />
          )}
        </Container>
      </form>
    </FormProvider>
  );
}

export default PassTemplateCreatePageForm;
